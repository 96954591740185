export default class BrockmanLazyLoad {
  constructor() {
    this.selector = 'script[data-lazyload=true]';
    this.observer = null;
    this.observedElements = [];
  }

  init() {
    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver((entries) =>
        this.observe(entries),
      );
      window.addEventListener('BrockmanTargetingCookiesAllowed', () =>
        this.refresh(),
      );
      window.addEventListener('BrockmanTargetingCookiesDisallowed', () =>
        this.run(),
      );
    } else this.run();
  }

  run() {
    const targets = document.querySelectorAll(this.selector);
    targets.forEach((target) => this.register(target.parentElement));
  }

  register(target) {
    if (!this.observer) {
      this.load(target);
      return;
    }

    const { cookiesSrc } = target.querySelector('script').dataset;
    if (cookiesSrc) {
      this.observer.observe(target);
      this.observedElements.push(target);
    }
  }

  deregister(target) {
    this.observer.unobserve(target);
    this.observedElements.splice(this.observedElements.indexOf(target), 1);
  }

  observe(entries) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;
      const { target } = entry;
      this.load(target);
    });
  }

  refreshScripts() {
    // destroy old BBVMS players if present
    if (window.bluebillywig?.players) {
      window.bluebillywig.players.reduceRight(
        (_, player) => player.destruct(),
        null,
      );
    }

    const elements = document.querySelectorAll(this.selector);
    elements.forEach((element) => {
      const target = element.parentElement;
      const script = target.querySelector('script');
      if (script.hasAttribute('src')) {
        const clonedScript = document.createElement('script');
        clonedScript.dataset.cookiesSrc = script.dataset.cookiesSrc;
        clonedScript.dataset.noCookiesSrc = script.dataset.noCookiesSrc;
        clonedScript.dataset.lazyload = true;
        if (script.getAttribute('onload')) {
          clonedScript.setAttribute('onload', script.getAttribute('onload'));
        }
        target.innerHTML = '';
        target.append(clonedScript);
      }
    });
  }

  refresh() {
    this.observedElements.forEach((element) =>
      this.observer.unobserve(element),
    );
    this.observedElements = [];
    this.refreshScripts();
    this.run();
  }

  load(target) {
    const targetElement = target.querySelector('script');
    const { cookiesSrc, nocookiesSrc } = targetElement.dataset;

    if (cookiesSrc && window.BrockmanAllowedCookies?.targeting) {
      targetElement.src = cookiesSrc;
    } else if (nocookiesSrc) {
      targetElement.src = nocookiesSrc;
    }

    if (!this.observer) return;
    this.deregister(target);
  }
}
