export default class BrockmanPoll {
  constructor(args) {
    this.wrapper = args.wrapper;
    this.poll = this.wrapper.querySelector('slot[name="poll"]');
    this.postEndpoint = args.postEndpoint;
    this.getEndpoint = args.getEndpoint;
    this.tags = args.tags;

    if (this.poll && this.poll.innerHTML) {
      this.inputs = this.poll.querySelectorAll('.poll_vote');
      this.submitButton = this.poll.querySelector('button.vote');
    }

    this.autoPoll = false;
    this.fixedPoll = false;
    this.storageValues = [];
    this.localStorageName = 'poll_answers';
    this.inputCheckedCounter = 0;

    this.loc = args.loc || {
      genericError: `Sorry, but an error occurred. Please try again.`,
      upNext: `Up next`,
      votedMessage: `You've already voted on this poll.`,
    };

    this.submitVotesHandler = (e) => this.submitVotes(e);
    this.changeButtonStateHandler = (e) => this.changeButtonState(e);
  }

  run() {
    if (this.wrapper.dataset.fixed === 'false') this.runAutoPoll();
    else if (this.wrapper.dataset.fixed === 'true') this.runFixedPoll();
    else this.register();
    this.wrapper.dataset.init = true;
  }

  register() {
    if (!this.poll) return;

    this.regenerateLocalStorage();
    if (this.getLocalStorage()) this.checkStorageMatch();

    this.inputs.forEach((input) => {
      if (input.type === 'radio') {
        input.addEventListener('click', this.submitVotes.bind(this));
      } else if (input.type === 'checkbox' && this.submitButton) {
        input.addEventListener('change', this.changeButtonState.bind(this));
      }
    });

    if (this.submitButton) {
      this.submitButton.addEventListener('click', this.submitVotes.bind(this));
    }
  }

  runAutoPoll() {
    this.autoPoll = true;
    this.regenerateLocalStorage();
    this.getNextPoll('', '');
  }

  runFixedPoll() {
    this.fixedPoll = true;
    this.regenerateLocalStorage();
    this.getNextPoll(this.poll.dataset.hashid, '');
  }

  regenerateLocalStorage() {
    const voteStorage = this.getLocalStorage();
    if (voteStorage) {
      const parsed = JSON.parse(voteStorage);
      this.storageValues = [...new Set(parsed)];
    }
  }

  checkStorageMatch() {
    // Check if storage values match any answers on the page
    // If they match, show their previously answered details
    if (this.storageValues) {
      const match = [...this.inputs].filter((el) =>
        this.storageValues.includes(el.dataset.hashid),
      );
      if (match && match.length > 1) {
        match.forEach((el) => {
          this.showPreviouslyVoted(el);
        });
      } else if (match.length) this.showPreviouslyVoted(match.shift());
    }
  }

  changeButtonState(e) {
    if (e.currentTarget.checked) this.inputCheckedCounter += 1;
    else this.inputCheckedCounter -= 1;
    this.submitButton.disabled = this.inputCheckedCounter <= 0;
  }

  submitVotes(e) {
    e.preventDefault();
    e.currentTarget.disabled = true;
    this.removeErrors();

    const votedAnswers = [];
    const hashids = [];
    const form = e.currentTarget.closest('.poll_form');

    this.inputs.forEach((input) => {
      input.classList.remove('invalid');
      if (input.checked) {
        const { hashid } = input.dataset;
        if (hashid.length > 0) {
          votedAnswers.push(input);
          hashids.push(hashid);
          if (this.isFunctionalAllowed()) this.setLocalStorage(hashid);
        } else {
          input.classList.add('invalid');
        }
      }
    });

    // Only update votes and get the next poll if voted
    // answers have a hashid
    if (hashids.length > 0 && votedAnswers) {
      const encodedHashids = encodeURIComponent(hashids);
      const postEndpoint = `${this.postEndpoint}?hashes=${encodedHashids}`;
      this.request(postEndpoint, form, votedAnswers);

      // Only get the next poll if the current poll is not sponsored
      // as the `up next` component would have been replaced by a CTA
      if (
        this.poll.dataset.sponsored === 'false' ||
        !this.poll.querySelector('.cta_link')
      )
        this.getNextPoll('', hashids);
    } else {
      this.renderError();
    }
  }

  getNextPoll(pollHashid = '', voteHashid = '') {
    // Add previously answered hashids as query string params
    // to exclude their polls from the `up next` poll.
    let queryValues = this.storageValues;
    let countryCode;
    if ('getCountryCode' in window) {
      countryCode = window.getCountryCode();
    }
    let getEndpoint;

    if (!pollHashid) {
      // Remove duplicate hashids
      if (voteHashid && Array.isArray(voteHashid) && voteHashid.length > 1) {
        voteHashid.forEach((hashid) => {
          queryValues = this.duplicatesRemoved(queryValues, hashid);
        });
      } else queryValues = this.duplicatesRemoved(queryValues, voteHashid);

      const encodedHashids = encodeURIComponent(voteHashid);
      const encodedValues = encodeURIComponent(queryValues);
      const encodedTags = encodeURIComponent(this.tags);
      getEndpoint = `${this.getEndpoint}?answer=${encodedHashids}&previous=${encodedValues}&country=${countryCode}&tag_slugs=${encodedTags}`;
    } else {
      getEndpoint = `${this.getEndpoint}?poll=${pollHashid}&country=${countryCode}`;
    }

    this.fetchNextPoll(getEndpoint);
  }

  duplicatesRemoved(values, hashid) {
    if (values.includes(hashid)) {
      const index = values.indexOf(hashid);
      values.splice(index, 1);
    }
    return values;
  }

  request(endpoint, form, answers) {
    fetch(new Request(endpoint), {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: new FormData(form),
    })
      .then((response) => this.status(response))
      .then((response) => response.json())
      .then((json) => this.processVotes(json, answers))
      .catch(() => this.renderError());
  }

  status(response) {
    if (response.ok && response.status >= 200 && response.status < 300)
      return response;
    throw new Error('Response not OK');
  }

  processVotes(json, answers) {
    const votedHashids = [];

    answers.forEach((answer) => {
      votedHashids.push(answer.dataset.hashid);
      this.sendTrackingData(answer);
    });

    if (json.total_votes) {
      const voteCount = this.poll.querySelector('.vote_count');
      const votes = parseInt(json.total_votes, 10).toLocaleString();
      voteCount.innerHTML = votes;
    }

    if (json.votes_by_answer) {
      this.inputs.forEach((input) => {
        json.votes_by_answer.forEach((answer) => {
          if (input.dataset.hashid === answer.hashid) {
            const percentageValue = parseInt(
              (answer.vote_count / json.votes_by_session) * 100,
              10,
            );
            const percentage = `${percentageValue}%`;
            if (votedHashids.includes(input.dataset.hashid)) {
              this.updateVotes(input, percentage, true);
            } else this.updateVotes(input, percentage);
          }
        });
      });
    }

    // Replace Up Next component with sponsor CTA
    if (
      this.poll.dataset.sponsored === 'true' &&
      this.poll.querySelector('.cta_link')
    )
      this.renderSponsorCTA(this.poll);

    window.sendZDAnalyticsEvent('polls_vote', 'User Tools', 'Polls', 'Vote');
  }

  sendTrackingData(answer) {
    const question = this.poll.querySelector('.question');
    const wrapper = answer.closest('.poll_vote_wrapper');
    const label = wrapper.querySelector('.poll_vote_label');
    const { signals } = answer.dataset;
    if (question && label) {
      this.sendToPermutive(question.innerText, signals, label.innerText);
      this.sendAnalyticsEvent(question.innerText, label.innerText);
    }
  }

  updateVotes(target, percentage, isCurrent = false) {
    this.disableButtons();
    const wrapper = target.closest('.poll_vote_wrapper');
    if (isCurrent) target.checked = true;
    this.addPercentageValue(percentage, wrapper);
  }

  showPreviouslyVoted(current) {
    this.inputs.forEach((input) => {
      const isCurrent = input === current;
      this.updateVotes(input, input.dataset.votePercentage, isCurrent);
    });
    const container = this.poll.closest('.poll_container');
    const alert = container.querySelector('.alert.info');

    // Replace previously voted alert with sponsor CTA
    if (
      this.poll.dataset.sponsored === 'true' &&
      this.poll.querySelector('.cta_link')
    ) {
      this.renderSponsorCTA(this.poll);
    } else if (!alert) this.renderAlert(this.loc.votedMessage, 'info');
  }

  async fetchNextPoll(endpoint) {
    const url = new URL(endpoint, window.location.origin);
    await fetch(url)
      .then((response) => this.status(response))
      .then((response) => response.text())
      .then((result) => this.processNextPoll(result))
      .catch(() => this.renderError());
  }

  processNextPoll(result) {
    if ((this.autoPoll || this.fixedPoll) && !result) this.processNoResult();
    else if (!result) return;

    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(result, 'text/html');
    const poll = htmlDocument.documentElement.querySelector('.poll');

    const { inactiveAnswers } = poll.dataset;
    if (inactiveAnswers !== '') this.removeInactivePolls(inactiveAnswers);

    if (this.autoPoll || this.fixedPoll) this.showNextPoll(result);
    else {
      const nextQuestion =
        htmlDocument.documentElement.querySelector('.question');
      const upNext = this.renderUpNext(nextQuestion);
      upNext.addEventListener('click', this.clickNextPoll.bind(this, result));
    }
  }

  removeInactivePolls(inactiveAnswers) {
    const answerArray = [];
    const { storageValues } = this;
    answerArray.push(inactiveAnswers);
    if (answerArray.length > 1) {
      answerArray.forEach((answer) => {
        const answerIndex = storageValues.indexOf(answer);
        if (answerIndex !== -1) storageValues.splice(answerIndex, 1);
      });
    } else {
      const index = storageValues.indexOf(inactiveAnswers);
      if (index !== -1) storageValues.splice(index, 1);
    }
    this.storageValues = storageValues;
    this.addLocalStorage(this.storageValues);
  }

  replacePollDetails(result, increaseCounter = false) {
    const container = this.wrapper.querySelector('.poll_container');
    let pollCounter = parseInt(container.dataset.playlistCount, 10);

    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(result, 'text/html');
    const poll = htmlDocument.documentElement.querySelector('.poll');
    const primaryColor = poll.style.getPropertyValue('--poll-primary-color');

    if (increaseCounter) {
      pollCounter += 1;
      container.dataset.playlistCount = pollCounter;
    }

    const wrapperStyles = container.closest('.poll_wrapper').style;
    wrapperStyles.setProperty('--poll-primary-color', primaryColor);
    wrapperStyles.setProperty('--poll-border-color', primaryColor);

    container.firstElementChild.outerHTML = result;
    const newPoll = container.firstElementChild;

    this.resetPoll(newPoll);

    this.register();
  }

  clickNextPoll(result, e) {
    e.preventDefault();
    this.replacePollDetails(result, true);
  }

  showNextPoll(result) {
    this.autoPoll = false;
    this.fixedPoll = false;
    this.replacePollDetails(result);
    this.wrapper.dataset.hydrated = true;
  }

  addPercentageValue(percentage, wrapper) {
    const label = wrapper.querySelector('.poll_vote_label');
    const existingPercentage = wrapper.querySelector('.percentage_text');
    wrapper.style.setProperty('--percentage-width', percentage);
    if (!existingPercentage) {
      const span = document.createElement('span');
      span.classList = 'percentage_text';
      span.innerHTML = percentage;
      this.insertElement(label, span);
    }
  }

  renderSponsorCTA(poll) {
    const container = poll.querySelector('.cta_container');
    if (container) container.classList.remove('hidden');
  }

  renderUpNext(nextQuestion) {
    const content = this.poll.querySelector('.poll_content');
    const question = nextQuestion;
    const upNext = document.createElement('button');
    upNext.classList = 'up_next';
    upNext.innerHTML = `
      <div class="play"></div>
      <div class="up_next_content">
        <span class="up_next_title">${this.loc.upNext}</span>
        <span class="up_next_question">${question.innerHTML}</span>
      </div>
    `;
    this.insertElement(content, upNext);
    return upNext;
  }

  processNoResult() {
    this.wrapper.remove();
  }

  isFunctionalAllowed() {
    // Check functional cookie permissions
    return (
      window.BrockmanAllowedCookies && window.BrockmanAllowedCookies.functional
    );
  }

  resetPoll(newPoll) {
    this.poll = newPoll;
    this.removeAds(this.poll);
    const voteCount = this.poll.querySelector('.vote_count');
    voteCount.innerHTML = parseInt(voteCount.innerHTML, 10).toLocaleString();
    this.inputs = newPoll.querySelectorAll('.poll_vote');
    this.submitButton = newPoll.querySelector('button.vote');
    this.inputCheckedCounter = 0;
    document.removeEventListener('click', this.submitVotesHandler);
    document.removeEventListener('change', this.changeButtonStateHandler);
  }

  removeAds(poll) {
    if (poll.dataset.sponsored === 'true') {
      const leaderAd = this.wrapper.querySelector(
        '.poll_leaderboard_container',
      );
      const mpuAd = this.wrapper.querySelector('.poll_ad_container');
      if (leaderAd) leaderAd.remove();
      if (mpuAd) mpuAd.remove();
    }
  }

  renderError() {
    this.removeErrors();
    this.renderAlert(this.loc.genericError, 'error');
  }

  renderAlert(text, classList) {
    const alert = document.createElement('div');
    alert.classList = `alert ${classList}`;
    alert.innerHTML = `${text}`;
    this.insertElement(this.poll, alert);
  }

  removeErrors() {
    const alerts = this.wrapper.querySelectorAll('.alert.error');
    if (alerts.length >= 1) {
      alerts.forEach((alert) => alert.remove());
    }
  }

  insertElement(parent, newElement) {
    if (parent) parent.parentNode.insertBefore(newElement, parent.nextSibling);
  }

  disableButtons() {
    if (this.inputs)
      this.inputs.forEach((input) => {
        input.disabled = true;
      });
    if (this.submitButton) {
      this.submitButton.disabled = true;
      this.submitButton.classList.add('hidden');
    }
  }

  getLocalStorage() {
    return localStorage.getItem(this.localStorageName);
  }

  setLocalStorage(hashid) {
    this.regenerateLocalStorage();
    this.storageValues.push(hashid);
    this.addLocalStorage(this.storageValues);
  }

  addLocalStorage(values) {
    localStorage.setItem(this.localStorageName, JSON.stringify(values));
  }

  sendToPermutive(question, signals, label) {
    if (window.permutive) {
      window.permutive.track('ApesterSurveyResponse', {
        answer: {
          text: label,
          signal: signals.split(','),
        },
        slide: { title: question },
      });
    }
  }

  sendAnalyticsEvent(poll, data) {
    if ('sendBrockmanAnalyticsEvent' in window)
      window.sendBrockmanAnalyticsEvent('poll', `quokkster: ${poll}`, `${poll} : ${data}`);
  }
}
